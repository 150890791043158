var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"show":_vm.showModal},on:{"close":_vm.hide}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title mt-0"},[_vm._v("Credits toevoegen en factuur versturen")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.hide}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('ValidationObserver',{ref:"add_credits_to_company",staticClass:"mt-3",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Aantal credits: "),_c('sup',[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"rules":"required|min:1","name":"Aantal credits"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.requestData.total_credits),expression:"requestData.total_credits"}],staticClass:"fusion-form-input",attrs:{"disabled":_vm.loading,"type":"number","min":"1"},domProps:{"value":(_vm.requestData.total_credits)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.requestData, "total_credits", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Prijs per credit: "),_c('sup',[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.requestData.price_per_credit),expression:"requestData.price_per_credit"}],staticClass:"fusion-form-input",attrs:{"disabled":_vm.loading,"type":"number"},domProps:{"value":(_vm.requestData.price_per_credit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.requestData, "price_per_credit", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Factuur versturen naar: "),_c('sup',[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.requestData.email),expression:"requestData.email"}],staticClass:"fusion-form-input",attrs:{"disabled":_vm.loading,"type":"email"},domProps:{"value":(_vm.requestData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.requestData, "email", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Factuurregel omschrijving: "),_c('sup',[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.requestData.description),expression:"requestData.description"}],staticClass:"fusion-form-input",attrs:{"disabled":_vm.loading,"type":"text"},domProps:{"value":(_vm.requestData.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.requestData, "description", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!invalid)?_c('div',[_c('strong',[_vm._v("Samenvatting")]),_c('table',{staticClass:"table"},[_c('tr',[_c('td',[_vm._v("Bedrijf")]),_c('th',[_vm._v(_vm._s(_vm.company.name))])]),_c('tr',[_c('td',[_vm._v("Factuur versturen naar")]),_c('th',[_vm._v(_vm._s(_vm.requestData.email))])]),_c('tr',[_c('td',[_vm._v("Factuurregel omschrijving")]),_c('th',[_vm._v(_vm._s(_vm.requestData.description))])]),_c('tr',[_c('td',[_vm._v("Aantal credits")]),_c('th',[_vm._v(_vm._s(_vm.requestData.total_credits))])]),_c('tr',[_c('td',[_vm._v("Prijs excl. btw")]),_c('th',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.requestData.total_credits * _vm.requestData.price_per_credit)))])]),_c('tr',[_c('td',[_vm._v("Prijs incl. btw")]),_c('th',[_vm._v(_vm._s(_vm._f("formatCurrency")((_vm.requestData.total_credits * _vm.requestData.price_per_credit) * 1.21)))])])])]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-12",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticStyle:{"padding-left":"0.5rem"}},[_c('button',{staticClass:"btn btn-outline-secondary float-start",attrs:{"type":"button","data-dismiss":"modal","disabled":_vm.loading},on:{"click":_vm.hide}},[_vm._v("Sluiten")]),_c('button',{staticClass:"btn btn-primary float-end",attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.addCreditsToCompany}},[_vm._v(" Credits toevoegen ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>

    <div>
        <div class="home mt-5">

            <fullscreen-loading v-if="loading"></fullscreen-loading>

            <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                <div class="row">
                    <div class="col-12">
                        <div class="fusion-title">
                            <h3 class="h3-green" style="font-size:50px;">Email templates</h3>
                        </div>
                        <p>
                            Bij email templates is het mogelijk om standaard email templates te definiëren die gekozen kunnen worden bij het aanmaken van een ondertekening. Binnen het onderteken proces worden meerdere emails verstuurd aan de hand van hun acties. Hieronder kan me de standaarden aanpassen en eventuele mergevelden gebruiken.
                        </p>
                    </div>
                </div>
            </div>


            <div class="row">

                <div class="col-md-3 mb-3">
                    <settings-menu></settings-menu>
                </div>

                <div class="col-md-9">
                    <h2 class="title-heading-left mb-3" style="">Email templates beheren</h2>


                    <ValidationObserver ref="accountsettings" v-slot="{ invalid }" tag="form">

                        <div class="row mb-3">
                            <div class="col-6">
                                <label>Selecteer email template:</label>

                                <select class="form-control" v-model="selectedEmailTemplate" @change="changeEmailTemplate()">
                                    <option :value="null">Maak een keuze</option>
                                    <option
                                        :value="emailTemplateOption.name"
                                        :key="emailTemplateOption.name"
                                        v-for="emailTemplateOption of emailTemplateOptions"
                                    >{{ emailTemplateOption.label }}</option>
                                </select>
                            </div>
                        </div>

                        <LanguageSelector
                            v-if="selectedEmailTemplate"
                            v-model="selectedLocale"
                            :locales="emailTemplateLocales"
                            @change="changeEmailTemplate"
                            class="mb-2"
                            :has-changes="hasEmailTemplateChanges"
                            changes-message="Weet je zeker dat je van taal wilt wisselen zonder de wijzigingen op te slaan in de geselecteerde taal?"
                        />

                        <div v-if="selectedEmailTemplate && selectedLocale">

                            <div class="row mb-3">
                                <div class="col-6">
                                    <label>Onderwerp:</label>
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <input type="text" class="fusion-form-input" v-model="request.subject" placeholder="" @change="hasEmailTemplateChanges = true">
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>

                                </div>
                            </div>


                            <div class="row mb-3">
                                <div class="col-12">
                                    <label>Template: </label>
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <trumbowyg v-model="request.template"
                                                   :config="editor_config"
                                                   class="form-control" name="content"
                                                   @tbw-change="hasEmailTemplateChanges = true"
                                        />
                                        <small>Vragen over de email templates/merge velden? Bekijk onze <a href="https://signly.nl/veel-gestelde-vragen/" target="_blank" style="color:#0c29f3">veel gestelde vragen</a></small>
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>

                                </div>
                            </div>


                            <button class="btn btn-success btn-caps" type="button" @click="saveEmailTemplate()" :disabled="invalid">
                                <font-awesome-icon icon="paper-plane"></font-awesome-icon>
                                Opslaan
                            </button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FullscreenLoading from '@/components/FullscreenLoading';
    import SettingsMenu from '@/components/SettingsMenu';
    import Trumbowyg from 'vue-trumbowyg';
    import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
    import {required} from 'vee-validate/dist/rules';
    import StateMixin from '../mixins/StateMixin';
    import LanguageSelector from '@/components/LanguageSelector.vue';
    import {mapState} from 'vuex';

    extend('required', {
        ...required,
        message: 'Dit veld is verplicht'
    });

    export default {
        components: {
            LanguageSelector,
            SettingsMenu,
            FullscreenLoading,
            ValidationProvider,
            Trumbowyg,
            ValidationObserver,
        },

        mixins: [
            StateMixin
        ],

        computed: {
            ...mapState({
                locales: state => state.locales
            }),

            emailTemplateOptions() {
                return this.defaultEmailTemplates.reduce((options, defaultEmailTemplate) => {

                    if (defaultEmailTemplate.locale === 'nl' && !options.find(option => option.name === defaultEmailTemplate.name)) {
                        options.push({
                            name: defaultEmailTemplate.name,
                            label: defaultEmailTemplate.label
                        })
                    }

                    return options;
                }, []);
            },

            emailTemplateLocales() {
                // We do not want to translate this message
                if (this.selectedEmailTemplate === 'signables_to_sign_request') {
                    return [
                        {
                            name: 'Nederlands',
                            locale: 'nl'
                        }
                    ];
                }

                return this.locales;
            }
        },

        data: function () {
            return {
                loading: false,
                request: {
                    id: null,
                    subject: null,
                    template: null,
                    name: null,
                },
                selectedEmailTemplate: null,
                selectedLocale: null,
                emailTemplates: [],
                defaultEmailTemplates: [],
                editor_config: {},
                hasEmailTemplateChanges: false
            }
        },

        created() {
            this.editor_config = this.$root.default_editor_config;

            const hasMergeFieldButton = this.editor_config.btns.some(btn => {
                return btn[0] === 'mergefields';
            });

            // If already attached do not re-add the btn
            if (hasMergeFieldButton) {
                return;
            }

            this.editor_config.btns.push(['mergefields']);

            if (!this.editor_config.plugins) {
                this.editor_config.plugins = {};
            }
        },

        async mounted() {
            await this.getDefaultEmailTemplates();
            await this.getEmailTemplates();

            if (this.selectedEmailTemplate === null || this.selectedLocale === null) {
                this.selectedEmailTemplate = 'sign_request_to_signer';
                this.selectedLocale = 'nl';
                this.changeEmailTemplate('nl');
            }
        },

        methods: {
            async getEmailTemplates() {
                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/email-templates');

                    this.emailTemplates = response.data;
                } catch (exception) {
                    this.errormessage = exception
                }
            },

            async getDefaultEmailTemplates() {
                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/email-templates/default');

                    this.defaultEmailTemplates = response.data;
                } catch (exception) {
                    this.errormessage = exception
                }
            },

            async saveEmailTemplate() {
                this.loading = true;

                try {

                    await this.$http.post(this.$root.api_url + '/api/email-templates', this.request);
                    await this.getEmailTemplates();

                } catch (exception) {
                    this.errormessage = exception
                }

                this.loading = false;
            },

            changeEmailTemplate() {
                const currentUserEmailTemplate = this.emailTemplates.find(emailTemplate => {
                    return emailTemplate.name === this.selectedEmailTemplate && emailTemplate.locale === this.selectedLocale;
                })

                const defaultEmailTemplate = this.defaultEmailTemplates.find(emailTemplate => {
                    return emailTemplate.name === this.selectedEmailTemplate && emailTemplate.locale === this.selectedLocale;
                });

                this.request = {
                    subject: null,
                    template: '',
                    name: this.selectedEmailTemplate,
                    locale: this.selectedLocale
                }

                if (currentUserEmailTemplate) {
                    this.request.id = currentUserEmailTemplate.id;
                    this.request.subject = currentUserEmailTemplate.subject;
                    this.request.template = currentUserEmailTemplate.template;
                } else if(defaultEmailTemplate) {
                    // No user template found, we try to set the default template
                    this.request.subject = defaultEmailTemplate.subject;
                    this.request.template = defaultEmailTemplate.template;
                }

                // Use the default email template to determine the merge fields
                if (defaultEmailTemplate) {
                    this.editor_config.plugins.mergefields = {
                        mergefields: defaultEmailTemplate ? defaultEmailTemplate.merge_fields.map(mergeField => `[[${mergeField}]]`) : []
                    };
                }

                // We have to use next tick since the trumbowyg does emit a change event when not needed...
                this.$nextTick(() => {
                    this.hasEmailTemplateChanges = false;
                });

                if (!this.emailTemplateLocales.find(locale => locale.locale === this.selectedLocale)) {
                    this.selectedLocale = 'nl';
                }
            },
        }
    }
</script>
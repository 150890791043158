import Axios from 'axios';
import i18n from './locale';

const axiosClient = (store, router) => {
    const client = Axios.create({
        baseURL: window.SERVER_BASE_URL
    });

// Add the locale to the request
    client.interceptors.request.use(function (config) {
        if (!config.headers['Content-Language']) {
            config.headers['Content-Language'] = store.state.locale || 'nl';
        }

        if (store.state.token) {
            config.headers['Authorization'] = `Bearer ${store.state.token}`;
        }

        return config;
    });

// Add a error message watcher
    client.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        let errorMessage = null;

        // Check where we can get the message
        if (error.response) {
            if (error.response.status === 401) {
                if (router) {
                    router.push({
                        name: 'login'
                    });
                }

                return Promise.reject(error);
            }

            // Only get response if not validation error
            if (error.response.status !== 422) {
                errorMessage = error.response.message || i18n.t('errors.title');
            }
        } else {
            // Get the message if we have no response
            errorMessage = i18n.t('errors.title');
        }

        if (errorMessage) {
            console.error(errorMessage);
            // store.dispatch('addError', errorMessage);
        }

        return Promise.reject(error);
    });

    return client;
};

export default axiosClient;

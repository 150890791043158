<template>
    <div>
        <div>
            <div class="home mt-5">

                <fullscreen-loading v-if="loading"></fullscreen-loading>

                <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                    <div class="row">
                        <div class="col-12">
                            <div class="fusion-title">
                                <h3 class="h3-green" style="font-size:50px;">Facturen</h3>
                            </div>
                            <p>
                                Hier vind je alle facturen terug die in het systeem zijn gegenereerd.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-3">
                        <settings-menu></settings-menu>
                    </div>

                    <div class="col-9">
                        <div class="row">
                            <h2 class="title-heading-left mb-3" style="">Facturen</h2>

                            <div class="d-flex mb-4">
                                <input placeholder="Zoek op debiteurnummer of debiteurnaam" name="search" @keydown.enter="getInvoices(1)"
                                       v-model="search" type="text" class="form-control input-sm">

                                <select class="ms-1" v-model="filters.state" @change="getInvoices(1)">
                                    <option :value="null">Alle</option>
                                    <option value="open">Open</option>
                                    <option value="paid">Betaald</option>
                                </select>

                                <select class="ms-1" v-model="filters.type" @change="getInvoices(1)">
                                    <option :value="null">Alle</option>
                                    <option value="manual">Handmatig</option>
                                    <option value="automatic">Automatisch</option>
                                </select>

                                <button @click="getInvoices(1)" class="btn btn-primary ms-1">
                                    <font-awesome-icon icon="search"></font-awesome-icon>
                                </button>
                            </div>

                            <div v-if="!invoices.data.length && !loading" class="col-12">
                                <div class="alert alert-info">
                                    Er zijn nog geen facturen
                                </div>
                            </div>

                            <div class="col-12 mb-3" v-for="invoice in invoices.data" :key="invoice.id">
                                <div class="card signcard">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="float-start">
                                                    <h2 class="h3-card-header">
                                                        Factuur <small>{{ invoice.invoice_no }}</small>
                                                        <span class="badge badge-sm bg-success ms-2" v-if="invoice.state === 'paid'">Betaald</span>
                                                        <span class="badge badge-sm bg-warning ms-2" v-if="invoice.state === 'open'">Open</span>
                                                    </h2>
                                                    <div><small>Datum: {{ invoice.invoice_date }}</small></div>
                                                    <div><small>Debiteur: {{ invoice.debtor_name }}</small></div>
                                                    <div><small>Debiteurnummer: {{ invoice.debtor_no }}</small></div>
                                                </div>

                                                <a :download="'Signly factuur ' + invoice.invoice_no + '.pdf'" :href="`/api/all-invoices/${invoice.id}/download?token=` + token" class="btn btn-sm btn-primary float-end">
                                                    <font-awesome-icon icon="download"/>
                                                </a>

                                                <button
                                                    v-if="invoice.type === 'manual'"
                                                    class="float-end btn btn-sm btn-info me-2 text-white"
                                                    v-tooltip="'Factuur bewerken'"
                                                    @click="editInvoice = { invoice }"
                                                >
                                                    <font-awesome-icon icon="pencil-alt"></font-awesome-icon>
                                                </button>
                                            </div>
                                            <div class="col-12" v-for="invoiceLine of invoice.invoice_lines" :key="invoiceLine.id">
                                                {{ invoiceLine.description }}
                                                <span class="float-end">&euro; {{ (invoiceLine.value * invoiceLine.amount).toFixed(2).replace('.', ',') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <Pagination :pagination="invoices" @change="getInvoices()"/>
                            </div>
                        </div>
                    </div>

                    <EditInvoiceModal v-if="editInvoice" :invoice="editInvoice.invoice" @close="editInvoice = null" @success="getInvoices(invoices.current_page)"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import SettingsMenu from '@/components/SettingsMenu';
    import FullscreenLoading from '@/components/FullscreenLoading';
    import Pagination from '../../components/Pagination';
    import {mapState} from 'vuex';
    import EditInvoiceModal from '@/views/admin/EditInvoiceModal.vue';

    export default {

        components: {
            EditInvoiceModal,
            FullscreenLoading,
            SettingsMenu,
            Pagination
        },

        computed: mapState({
            token: state => state.token
        }),

        data() {
            return {
                loading: false,
                order: 'id,desc',
                editInvoice: null,
                search: null,
                filters: {
                    state: null,
                    type: null
                },
                invoices: {
                    data: []
                }
            };
        },

        mounted() {
            this.getInvoices();
        },

        methods: {
            async getInvoices(page = undefined) {
                this.loading = true;

                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/all-invoices', {
                        params: {
                            page: page || this.invoices.current_page,
                            filters: JSON.stringify([
                                this.filters.state ? {
                                    field: 'state',
                                    eq: this.filters.state
                                } : null,
                                this.filters.type ? {
                                    field: 'type',
                                    eq: this.filters.type
                                } : null
                            ].filter(Boolean)),
                            search: this.search,
                            order: this.order,
                            limit: 10
                        }
                    });

                    this.invoices = response.data.invoices;
                } catch (exception) {
                    this.errormessage = exception;
                }

                this.loading = false;
            }
        }
    };

</script>

<style scoped>

</style>

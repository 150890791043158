<template>
    <span class="fi" :class="'fi-' + (locale === 'en' ? 'gb' : locale)"/>
</template>
<script>
    export default {

        props: {
            locale: {
                type: String,
                required: true
            }
        }
    }
</script>
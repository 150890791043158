import Axios from "axios";

const dataLoadMiddleware = async (store, to, from, next, types) => {

    const requestConfig = store.state.token ? {
        headers: {
            Authorization: `Bearer ${store.state.token}`,
            'Content-Language': store.state.locale || 'nl'
        }
    } : {};


    if (types.indexOf('company') !== -1 && store.state.company === null && store.state.token) {
        const response = await Axios.get('/api/company', requestConfig);

        store.dispatch('company', response.data);
    }


    if (types.indexOf('locales') !== -1 && store.state.locales === null && store.state.token) {
        const response = await Axios.get('/api/locales', requestConfig);

        store.commit('setLocales', response.data);
    }

    return next();
}

export default dataLoadMiddleware;

<template>
    <div class="d-flex align-items-center">
        <div
            v-for="locale of locales"
            :key="locale.locale"
            v-tooltip="locale.name"
            @click="save(locale.locale)"
            class="language-selector-item"
            :class="{ active: locale.locale === value }"
        >
            <LanguageFlag :locale="locale.locale"/>
        </div>
    </div>
</template>
<script>

    import LanguageFlag from '@/components/LanguageFlag.vue';

    export default {
        components: {LanguageFlag},

        props: {
            value: {
                type: String,
                default: null
            },

            locales: {
                type: Array,
                default() {
                    return [];
                }
            },

            hasChanges: {
                type: Boolean,
                default: false
            },

            changesMessage: {
                type: String,
                default: null
            }
        },

        methods: {
            save(locale) {
                if (this.hasChanges && !confirm(this.changesMessage)) {
                    return;
                }

                this.$emit('input', locale);
                this.$emit('change', locale);
            }
        }
    };
</script>

<style scoped>
    .language-selector-item {
        margin-right: 5px;
        border-bottom: 2px solid transparent;
        width: 50px;
        text-align: center;
    }

    .language-selector-item.active {
        border-color: #198754;
    }
</style>
<template>
<Modal :show="showModal" @close="hide">
    <div slot="header">
        <div class="modal-header">
            <h5 class="modal-title mt-0">Credits toevoegen en factuur versturen</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="hide"><span aria-hidden="true">×</span></button>
        </div>
    </div>

    <div slot="body">
        <ValidationObserver ref="add_credits_to_company" v-slot="{ invalid }" tag="form" class="mt-3">
            <div class="mb-3">
                <label>Aantal credits: <sup>*</sup></label>
                <ValidationProvider rules="required|min:1" v-slot="{ errors }" name="Aantal credits">
                    <input :disabled="loading" type="number" min="1" class="fusion-form-input" v-model="requestData.total_credits">
                    <small class="error">{{ errors[0] }}</small>
                </ValidationProvider>
            </div>

            <div class="mb-3">
                <label>Prijs per credit: <sup>*</sup></label>
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <input :disabled="loading" type="number" class="fusion-form-input" v-model="requestData.price_per_credit">
                    <small class="error">{{ errors[0] }}</small>
                </ValidationProvider>
            </div>

            <div class="mb-3">
                <label>Factuur versturen naar: <sup>*</sup></label>
                <ValidationProvider rules="required|email" v-slot="{ errors }">
                    <input :disabled="loading" type="email" class="fusion-form-input" v-model="requestData.email">
                    <small class="error">{{ errors[0] }}</small>
                </ValidationProvider>
            </div>

            <div class="mb-3">
                <label>Factuurregel omschrijving: <sup>*</sup></label>
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <input :disabled="loading" type="text" class="fusion-form-input" v-model="requestData.description">
                    <small class="error">{{ errors[0] }}</small>
                </ValidationProvider>
            </div>

            <div v-if="!invalid">
                <strong>Samenvatting</strong>
                <table class="table">
                    <tr>
                        <td>Bedrijf</td>
                        <th>{{ company.name }}</th>
                    </tr>
                    <tr>
                        <td>Factuur versturen naar</td>
                        <th>{{ requestData.email }}</th>
                    </tr>
                    <tr>
                        <td>Factuurregel omschrijving</td>
                        <th>{{ requestData.description }}</th>
                    </tr>
                    <tr>
                        <td>Aantal credits</td>
                        <th>{{ requestData.total_credits }}</th>
                    </tr>
                    <tr>
                        <td>Prijs excl. btw</td>
                        <th>{{ requestData.total_credits * requestData.price_per_credit | formatCurrency }}</th>
                    </tr>
                    <tr>
                        <td>Prijs incl. btw</td>
                        <th>{{ (requestData.total_credits * requestData.price_per_credit) * 1.21 | formatCurrency }}</th>
                    </tr>
                </table>
            </div>
        </ValidationObserver>
    </div>

    <div slot="footer" class="col-12">
        <div style="padding-left: 0.5rem">
            <button type="button" class="btn btn-outline-secondary float-start" data-dismiss="modal" @click="hide" :disabled="loading">Sluiten</button>

            <button type="button" class="btn btn-primary float-end" @click="addCreditsToCompany" :disabled="loading">
                Credits toevoegen
            </button>
        </div>
    </div>
</Modal>
</template>
<script>
    import Modal from '@/components/Modal.vue';
    import {ValidationObserver, ValidationProvider} from 'vee-validate';

    export default {
        components: {
            ValidationObserver,
            ValidationProvider,
            Modal,
        },

        props: {
            user: {
                type: Object,
                required: true
            },

            company: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                showModal: true,
                loading: false,
                requestData: {
                    total_credits: null,
                    price_per_credit: null,
                    email: this.user.email,
                    description: 'Credits'
                }
            };
        },

        methods: {
            async addCreditsToCompany() {
                if (!(await this.$refs.add_credits_to_company.validate())) {
                    return;
                }

                this.loading = true;

                try {
                    await this.$http.post('/api/companies/' + this.company.id + '/add-credits-with-invoice', this.requestData);

                    this.$notify({
                        group: 'default',
                        title: 'Credits toegevoegd en factuur opgestuurd',
                        text: 'Credits toegevoegd en factuur opgestuurd',
                        type: 'success',
                        duration: 10000,
                    });

                    this.$emit('success');
                    this.hide();
                } catch (exception) {
                    alert(exception);
                }

                this.loading = false;
            },

            hide() {
                this.showModal = false;
                this.$emit('close');
            }
        }
    };
</script>
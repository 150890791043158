<template>
    <multiselect
        v-model="localValue"
        :options="locales"
        @input="save"
        label="name"
        track-by="locale"
        :placeholder="'Selecteer een taal'"
        :searchable="false"
    >
        <template #singleLabel="{ option }">
            <LanguageFlag :locale="option.locale"/>
            {{ option.name }}
        </template>

        <template #option="{ option }">
            <LanguageFlag :locale="option.locale"/>
            {{ option.name }}
        </template>
    </multiselect>
</template>
<script>
    import {mapState} from 'vuex';
    import LanguageFlag from '@/components/LanguageFlag.vue';

    export default {
        components: {LanguageFlag},
        props: {
            value: {
                type: String,
                default: null,
            }
        },

        computed: {
            ...mapState({
                locales: state => state.locales
            })
        },

        watch: {
            value() {
                this.localValue = this.buildLocalValue(this.value);
            }
        },

        data() {
            return {
                localValue: null
            };
        },

        mounted() {
            this.localValue = this.buildLocalValue(this.value);
        },

        methods: {
            buildLocalValue(value) {
                if (!value) {
                    return null;
                }

                return (this.locales || []).find(locale => locale.locale === value);
            },

            save() {
                const value = this.localValue ? this.localValue.locale : null;
                this.$emit('input', value);
                this.$emit('change', value);
            }
        }
    };
</script>